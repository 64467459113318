.common-form__label {
    grid-column: 1 / 2;
    text-transform: uppercase;
        color: #ffffff94;
}
.common-form__input {
    grid-column: 2/3;
        border-width: 2px;
        border: none;
        height: 50px;
        font-size: 1.5em;
        background: #570c0c;
        border-radius: 10px;
        padding: 0 10px;
        color: #dbdbdb;

    &--error {
        border-color: #d2232a;
        border-style: dashed;
    }
}
.common-form__validation-error {
    color: #d2232a;
    font-size: 0.75em;
    grid-column: 1 / 3;
    margin: 0px 0 5px auto;
}
