.user-feedback-drawer {
    color: #DDDDDD;

    &__h1 {
        text-transform: uppercase;
    }

    &__p {
        font-size: 14px;
        line-height: 1.57;
        margin-bottom: 20px;
    }

    &__fixed-toggle-button {
        position: fixed;
            padding: 5px 15px;
            border-radius: 50px;
            background-color: #FFC107;
            left: 50%;
            transform: translateX(-50%);
            bottom: 10px;
            /* text-decoration: underline; */
            text-transform: uppercase;
            font-weight: 900;
            color: #000000;
            cursor: pointer;
            user-select: none;
            text-align: center;
    }

    &__close-button__container {
        display: block;
        margin-left: auto;
        margin-top: 10px;
        width: fit-content;
    }

    &__submit-button__container {
        display: block;
        margin-left: auto;
        margin-top: 10px;
        width: fit-content;
    }

    &__textarea {
        margin: auto;
        width: 100%;
        height: 80px;
    }

    &__label--visually-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    
    &__notification {
        background-color: #dddddd;
        text-align: center;
        border-radius: 8px;
        padding: 3px;

        &--positive {
            color:#37bf7a;
        }

        &--negative {
            color: #bf377a;
        }
    }
}