.compare-selection-buttons{
    
    &__compare-button__container {
        display: inline-block;
        margin: 0 5px;
    }

    &__cancel-button__container {
        display: inline-block;
        margin: 0 5px;
    }

    &__clear-button__container {
        display: inline-block;
        margin: 0 5px;
    }

    &__container {
        width: fit-content;
        margin: 0 auto;
    }
}

.checkbox {
    cursor: pointer;
}

.checkbox > input {
    appearance: none;
    height: 25px;
    width: 25px;
    border: 1px solid black;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: white;
    cursor: pointer;
}
 
.checkbox > input:checked {
    border: 1px solid black;
    background-color: #d2232a;
}

.checkbox > input:active {
    border: 2px solid #34495E;
}