.athlete-form {
    margin: 0 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 12px 12px;
    align-items: center;
}
.athlete-form__label--hidden {
    display: none;
}
.athlete-form__input--hidden {
    display: none;
}
.athlete-form__buttons {
    grid-column: 2/3;
    place-self: center right;

    &__cancel-container, &__submit-container {
        display: inline-block;
        margin: 10px;
        text-transform: uppercase;
    }
}