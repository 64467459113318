.account-login-form {
    margin: 0 0 0 20px;


    &__container {
        width: 320px;
        margin:0 auto;
        margin-top: 50px;
    }

    &__heading {
        margin: 30px;

        &__logo, &__text {
            width: fit-content;
            display: block;
            margin: 0 auto;
                margin-bottom: 18px;
                    font-size: 24px;
                        color: #fff;
        }

    }

    &__label {
        display: block;
    }

    &__status-message {
        border-radius: 4px;
        padding: 5px 10px;
        color: #fff;

        &--success {
            border: solid;
            border-color: #6fc370;
        }

        &--error {
            border: solid;
            border-color: #e17f82;
        }

        &--info {
            border: solid;
            border-color: #73a4bd;
        }

        &__container {
            width: fit-content;
            margin: 0 auto;
        }
    }

    &__submit-button__container {
        float:right;
        margin: 10px 0;
    }

    .common-form__input {
        margin: 10px 0;
    }
}
