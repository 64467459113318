.data-table {
    text-transform: uppercase;
    border-collapse: collapse;
    width: 100%;
        background: #3f0b0b;
            color: #e9e9e9a6;
    border-radius: 20px;

    &__container {
        margin: 20px 5px;
    }
}

@media (min-width: 500px) {

    .data-table__container{
        margin:20px;
    }

}