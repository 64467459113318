.results-graph {
    &__container {
        width: 590px;
        height: fit-content;
        margin: 0px auto 100px auto;
        border: solid thin #E5E5E5;
    }

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        border: solid thin #E5E5E5;
        background-color: #E5E5E5;
        padding: 20px 0;
    }

    &__legend {

        &__key {

            &__label-text {
                margin-left: 10px;
                font-weight: 500;
            }

            &__checkbox {
                cursor: pointer;
                display: flex;
                align-items: center;
            }

            &__checkbox > input {
                appearance: none;
                height: 25px;
                width: 25px;
                border: 1px solid;
                border-radius: 4px;
                outline: none;
                transition-duration: 0.3s;
                background-color: white;
                cursor: pointer;
            }
        }
        
        &__container {
            margin: 20px;
        }
    }
}

@media (min-width: 760px) {
    .results-graph {
        &__container {
            width: 710px;
        }
    }
}

@media (min-width: 1024px) {
    .results-graph {
        &__container {
            width: 970px;
        }
    }
}