.start-session__button__container {
    margin-left: auto;
    width: fit-content;
}

.add-athlete-button {
    border-radius: 10px;
        padding: 20px 10px;
        margin: -10px 20px 0px 20px;
        color: #CDCDCD;
        border: 2px dashed #CDCDCD;
        user-select: none;
        cursor: pointer;
        font-size: 20px;
}

a.athlete-link {
    font-size: 20px;
}