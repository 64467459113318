
.common-styled-dropdown {
    border-color: rgb(210, 35, 42);
      border-style: solid;
      width: fit-content;
      margin: 0px auto 20px;
      // text-transform: uppercase;
      font-size: 20px;
      font-weight: 900;
      border-radius: 50px;
      padding-right: 20px;
      border: none;
      /* background: red; */
      /* color: white; */

    &__label {
      display: inline-block;
        /* background-color: rgb(210, 35, 42); */
        padding: 11.5px 18px;
        color: #ffffff5c;
        text-align: center;
        width: -webkit-fill-available;
        border-radius: 50px;
        }


    &__dropdown__container{
        white-space: nowrap;
        align-items: center;
    }

    &__dropdown { 
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }
        font-size: 1.2em;
        width: 100%;
        cursor: pointer;
        display: inline-block;
        grid-template-areas: "select";
        grid-area: select;
        align-items: center;

        select {
            appearance: none;
              background-color: transparent;
              color: rgb(255 255 255);
              text-transform: uppercase;
              border: none;
              padding: 0px 10px 0px 0px;
              margin: 0px;
              width: 100%;
              height: 32px;
              font-family: inherit;
              font-size: inherit;
              font-weight: 900;
              cursor: inherit;
              line-height: inherit;
              text-align: center;
              text-align-last: center;
          }
    }
    &__dropdown::after {
    display: inline-block;
      content: "";
      width: 1em;
      height: 1em;
      border-radius: 50%;
      /* border-color: white; */
      /* border-style: solid; */
      background: #d2232a url('data:image/svg+xml;utf8,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path transform="translate(2.8, 4.2)" d=" M9.53674e-07 -3.49691e-07L2.77551 -2.2837e-07L4 1.85526L5.26531 -1.19537e-07L8 0L4 6L9.53674e-07 -3.49691e-07Z" fill=" white"/></svg>') no-repeat;
     position: relative;
        top: 4px;
          left: -12px;
      pointer-events: none;
      background-position: 0px 0px;
      background-size: 22px;
      }
}

@media (min-width: 760px) {
.common-styled-dropdown {
    &__label {
      width: auto;
    }

    &__dropdown__container{
      display: inline-block;
    }
  }
}