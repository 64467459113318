.user-dropdown{
  
            position: relative;
            user-select: none;
            font-weight: 900;
            background: #00000087;
            border-radius: 10px;
            padding: 8px 14px;

    &__username {
        padding: 5px;
        white-space: nowrap;
        color: #d2232a;
        position: relative;
        cursor: pointer;

        &__text {
            display: inline-block;
        }

        &__img {
            display: inline-block;
            margin-left: 9px;
        }

    }

    &__menu {

        text-align: center;
        padding: 5px;
        position: absolute;
        left: 0px;
        right: 0px;

        a:link,a:visited {
            color: #d2232a;
            text-decoration: none;
        }

        &--closed {
            visibility: hidden;
        }
    }
}