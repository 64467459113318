.o-radioSelect {
    list-style: none;
    padding: 0;
    &__item {
        margin-bottom: 10px;
    }
    &__label {
        display: flex;
        align-items: flex-end;
    }
    &__input {
        margin-right: 20px;
    }

    &.-horizontal {
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: flex-end;
    }

    &__title {
        &.-centerLabel {
            width: fit-content;
            margin: auto;
        }
    }
}

.a-radioInput {
    display: flex;
    &__input {
        margin: 0;
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .a-radioInput__checkbox::after {
            display: block
        }
    }
    &__checkbox {
        display: block;
        position: relative;
        width: 1em;
        height: 1em;
        border-radius: 8%;
        border: .1em solid #d2232a;

        &::after {
            content: '';
            display: none;
            width: 80%;
            height: 80%;
            margin: 10%;
            background: #d2232a;
            border-radius: 16%;
        }
    }
}
