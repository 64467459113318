.a-commonButton {
border: 0;
    color: black;
    font-family: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    background-color: #f9a503;
    border-radius: 4px;
    width: fit-content;
    padding: 10px 15px;
    user-select: none;
    cursor: pointer;
    display: block;
    text-align: center;
    border-radius: 50px;
    font-weight: bold;

    &.-red {
    color: white;
        background-color: #d2232a;
    }
        &.-green {
    color: white;
        background-color: #026b2b;
    }

           &.-dark {
               color: white;
               background-color: #3d0406;
                border-radius: 4px;
           }
}

@media (min-width: 760px) {
    .commonButton {
        white-space: nowrap;
    }
}