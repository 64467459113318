
tr.data-row:nth-child(odd)  {
    // background-color: #E5E5E5;
}
.data-row__value {
    padding:0 7px;
    height: 60px;
}

.data-row--highlighted {
    box-shadow:inset 0px 0px 0px 2px #d2232a;
}

@media (min-width: 500px) {

    .data-row__value {
        padding: 0px 15px 0px 15px;
    }

}